import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AppRoute } from 'src/app/models/interfaces/app-route';

@Injectable({
  providedIn: 'root'
})
export class NavigationApiService {

  constructor(
    private http: HttpClient) {}

  fetchMenuItems(language): Observable<AppRoute[]> {
    const url = `${language}/api/menu_items/main`;
    return this.http.get<any>(url);
  }

  fetchFooterData(language = '/ee'): Observable<any> {
    const url = `/api/footer${language}`;
    return this.http.get<any>(url);
  }
}
