import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiBaseurlInterceptorService } from './interceptors/api-baseurl-interceptor.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor.interceptor';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NgxGoogleAnalyticsModule.forRoot(environment.analytics),
    NgxGoogleAnalyticsRouterModule,
    AppRoutingModule,
    CoreModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiBaseurlInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
