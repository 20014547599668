import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationFacadeService } from '../features/navigation/navigation-facade.service';
import { Location } from '@angular/common';
import { languages } from '../features/navigation/components/helpers/languages';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private navigationFacade: NavigationFacadeService,
    private location: Location
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error, caught) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) {
            const subscription = this.navigationFacade.language.subscribe((lang) => {
              const failedUrl = new URL(req.url)?.pathname;
              //console.log(window.location.origin);
              //console.log(req.url.replace(window.location.origin, ''));
              //const failedUrl = req.url.replace(window.location.origin, '').replace('?_format=json', '');
              this.router.navigate([`${lang ? lang : languages[0].key}/404`]);
              const replaceSubscription = this.router.events.subscribe((event) => {
                if(event instanceof NavigationEnd) {
                  this.location.replaceState(failedUrl);
                  replaceSubscription.unsubscribe();
                }
              });
            });
            subscription.unsubscribe();
          }

          return throwError(error);
        }
        return caught;
      }),
    );
  }
}
