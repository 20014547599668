import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiBaseurlInterceptorService {
  EXCLUDED_URLS: string[] = [
    'http://localhost:1337',
  ];
  url = environment.be_url;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.EXCLUDED_URLS.some(path => request.url.includes(path))) {
      return next.handle(request);
    }

    const url: string = this.url + request.url;
    request = request.clone({ url });
    
    return next.handle(request);
  }
}
