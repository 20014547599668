import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import et from '@angular/common/locales/et';
registerLocaleData(et);
/*
We have a CoreModule that defines our:
  1. singleton services (eg Authentication)
  2. single-instance components
  3. configuration
  4. export any third-party modules needed in AppModule.

This module is imported only once in AppModule.
*/

@NgModule({
  imports: [
  ],
  exports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'et-EE' },
  ]
})
export class CoreModule {}
