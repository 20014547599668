export const languages = [
    {
        key: '/ee',
        label: 'EST',
        lang: 'et',
    },
    {
        key: '/en',
        label: 'ENG',
        lang: 'en',
    }
];
