import { Injectable, Inject, Injector } from '@angular/core';
import { NavigationApiService } from './services/navigation-api.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { languages } from './components/helpers/languages';
import { AppRoute } from 'src/app/models/interfaces/app-route';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface FooterProperties {
  sticky: boolean;
  disabled: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class NavigationFacadeService {

  private routes$ = new BehaviorSubject<AppRoute[]>(null);
  public activeFirstLevelRoute$ = new BehaviorSubject<AppRoute>(null);
  public sidemenuRoutes$ = new BehaviorSubject<AppRoute[]>(null);
  private routeList$ = new BehaviorSubject<any[]>(null);
  private language$ = new BehaviorSubject<any>('/ee');
  private menuLeft$ = new BehaviorSubject<number>(null);
  private footerData$ = new BehaviorSubject<any>(null);
  public footerProperties$ = new BehaviorSubject<FooterProperties>(null);

  lang: string;
  subscription: any;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private navigationApi: NavigationApiService,
    private router: Router,
    private http: HttpClient,
    private titleService: Title,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.titleService.setTitle('Haridusandmete portaal | haridussilm.ee');
      }
    });
  }

  get routeList() {
    return this.routeList$.asObservable();
  }

  get routes(): Observable<AppRoute[]> {
    return this.routes$.asObservable();
  }

  get sidemenuRoutes(): Observable<AppRoute[]> {
    return this.sidemenuRoutes$.asObservable();
  }

  get activeFirstLevelRoute(): Observable<AppRoute> {
    return this.activeFirstLevelRoute$.asObservable();
  }

  get language() {
    return this.language$.asObservable();
  }

  get menuLeft() {
    return this.menuLeft$.asObservable();
  }

  get footerData() {
    return this.footerData$.asObservable();
  }

  setMenuLeft(value: number): void {
    this.menuLeft$.next(value);
  }

  fetchMenuItems(language = languages[0].key): void {
    this.language$.next(language);
    this.navigationApi.fetchMenuItems(language).subscribe((data: AppRoute[]) => {
      const categorizedRoutes = this.categorizedRoutes(data);
      this.routes$.next(this.expandMatchingMenus(categorizedRoutes, this.router.url, 0));
      this.setRouteListForBreadcrumbs(data);
    });
  }

  expandMatchingMenus(routes: AppRoute[], url: string, depth: number): AppRoute[] {
    for (const route of routes) {
      route.expanded = false;
      if (url.includes(route.relative)) {
        route.expanded = true;
        if (!depth) {
          this.activeFirstLevelRoute$.next(route);
        }
        if (route?.below?.length && depth === 1) {
          this.sidemenuRoutes$.next(route.below);
        }
      }
      if (route?.below?.length) {
        route.below = this.expandMatchingMenus(route.below, url, depth + 1);
      }
    }
    return routes;
  }

  resetExpandedMenuStates(routes: AppRoute[]): AppRoute[] {
    for (const route of routes) {
      route.expanded = false;
      /**
       * Initially only reset first level...
       */
      // if (route?.below?.length) {
      //   route.below = this.resetExpandedMenuStates(route.below);
      // }
    }
    return routes;
  }

  fetchFooterData(language = languages[0].key): void {
    this.navigationApi.fetchFooterData(language).subscribe((data) => {
      this.footerData$.next(data);
    });
  }

  setRouteListForBreadcrumbs(data): void {
    this.routeList$.next([]);
    this.checkRouteListItem(data);
  }

  checkRouteListItem(data): void {
    if (Array.isArray(data)) {
      data.map((element) => {
        this.addRouteListItem(element);
      });
    } else {
      this.addRouteListItem(data);
    }
  }

  addRouteListItem(data): void {
    if (data?.relative && data?.title) {
      const currentList = this.routeList$.value;
      const { relative, title, isCategory } = data;
      const routeListObject = {
        relative, title, isCategory
      };
      this.routeList$.next([...currentList, routeListObject]);
      if (data?.below) {
        this.checkRouteListItem(data.below);
      }
    }
  }

  setInitLang(language) {
    this.lang = language.key;
    this.document.documentElement.lang = language.lang;
    this.language$.next(language.key);
    this.fetchMenuItems(language.key);
    if (this.router.url === language.key || this.router.url === '/') {
      this.router.navigate([language.key], { replaceUrl: true });
    }
  }

  // setLang(language) {
  //   this.lang = language.key;
  //   this.document.documentElement.lang = language.lang;
  //   this.language$.next(language.key);
  //   this.fetchMenuItems(language.key);
  //   const data = this.injector.get(PowerbiFacadeService).data;
  //   if (data?.nid && data?.langcode[0]?.value !== language.lang) {
  //     this.navToAlias(data?.nid[0]?.value);
  //   } else {
  //     this.router.navigate([language.key], { replaceUrl: true });
  //   }
  // }

  navToAlias(nid) {
    this.http.get(`${this.lang}/node/${nid}?_format=json`)
      .subscribe((res: any) => {
        this.router.navigate(`${this.lang}${res.path[0].alias}`.split('/'));
      });
  }

  navToExternal(url: string): Observable<string> {
    return this.http.get(`${url}?_format=json`).pipe(map((response: any) => {
      return response.field_external_link[0].uri;
    }));
  }

  categorizedRoutes(routes: AppRoute[]): AppRoute[] {
    for (const route of routes) {
      route.isCategory = route?.description?.includes('category');
      route.external = route?.description?.includes('external');
      if (route.below && route.below.length) {
        this.categorizedRoutes(route.below);
      }
    }
    return routes;
  }
}
